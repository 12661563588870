import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { motion } from "framer-motion"

// GQL Fragments
import { QueryFragments } from "../GraphQL/queryFragments" //eslint-disable-line

// Hooks, Querys & State
import { useAppState } from "../state/appState"

import { PageMeta } from "../components/pageMeta"
import BlockZone from "../components/blocks/blockZone"
import { ScrollProgress } from "../components/ui"
import { RelatedJournal  } from "../components/blocks"
import { CtaContent } from "../components/ui"

// Animation
const variants = {
  visible: {
    opacity: 1,
    transition: {
      delay: 0,
      duration: 0.7,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      delay: 0,
      duration: 0.7,
    },
  },
}

const BlogPost = ({ data: { page, relatedPosts } }) => {
  
  const { meta, main, category, introText, pageBlocks } = page || {}

  const {
    setGlobalTheme,
    toggleHideFooter,
    toggleHideHeader,
    toggleHeaderUnderline,
  } = useAppState()

  useEffect(() => {
    toggleHideFooter(false)
    toggleHideHeader(true)
    setGlobalTheme("light")
    toggleHeaderUnderline(false)
  }, [
    toggleHideFooter,
    toggleHideHeader,
    setGlobalTheme,
    toggleHeaderUnderline,
  ])  

  return (
    <div id="progress-wrapper" className="">
      <PageMeta {...meta} />
      <ScrollProgress target={`/journal`} />
      <div className="grid grid-rows-1 grid-cols-14">
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={variants}
          className="col-start-2 col-end-14 lg:col-end-12 xl:col-end-11  2xl:col-end-9 3xl:col-end-9 row-start-1 z-10 flex flex-col justify-center lg:justify-start py-24 lg:py-48 3xl:py-64 2xl:my-20"
        >
          {category.main.title && (
            <p className="tracking-wide lg:text-2xl 2xl:text-3xl mb-6 font-medium">
              {category.main.title}
            </p>
          )}
          {main.title && (
            <h1 className="font-serif font-medium text-3xl lg:text-7xl">{main.title}</h1>
          )}
          {introText && <h2 className="mt-8 font-sans text-xl lg:text-4xl">{introText}</h2>}
        </motion.div>
      </div>
      {pageBlocks && <BlockZone {...pageBlocks} />}
      <RelatedJournal items={relatedPosts} />
      <CtaContent spacing={{ padTop: '3', padBot: '3' }} />
    </div>
  );
}

export default BlogPost

export const pageQuery = graphql`
  query blogPostQuery($slug: String!) {
    page: sanityBlogPost(main: { slug: { current: { eq: $slug } } }) {
      introText
      main {
        title
      }
      category {
        main {
          title
        }
      }
      pageBlocks {
        ...BlockZoneBlocks
      }
      meta {
        ...MetaCardFields
      }
    }
    relatedPosts: allSanityBlogPost(
      sort: { fields: date, order: DESC }
      filter: { main: { slug: { current: { ne: $slug } } } }
      limit: 2
    ) {
      edges {
        node {
          date(formatString: "DD MMM YYYY")
          main {
            title
            slug {
              current
            }
          }
          category {
            main {
              title
            }
          }
          portalImage {
            image {
              ...ImageWithPreview
            }
          }
        }
      }
    }
  }
`;
